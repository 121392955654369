// src/pages/_app.tsx
import "../styles/globals.css";
import type { AppType } from "next/app";
import { ClerkProvider } from "@clerk/nextjs";

import { trpc } from "@30p/ui/utils/trpc.web";

const MyApp: AppType = ({ Component, pageProps: { ...pageProps } }) => {
  return (
    <ClerkProvider
      afterSignInUrl="/dashboard"
      // signUpUrl="/sign-up"
      // localization={{
      //   footerPageLink__privacy:
      //     "By signing up you accept our terms and conditions",
      // }}
      // appearance={{
      //   layout: {
      //     termsPageUrl: "/terms",
      //     privacyPageUrl: "/privacy",
      //   },
      // }}
    >
      <Component {...pageProps} />
    </ClerkProvider>
  );
};

export default trpc.withTRPC(MyApp);
